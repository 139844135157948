import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../hooks/use-site-metadata'

const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  flex: 1;
  ul {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: stretch;
    font-size: ${props => props.theme.fontSizes[2]}px;
    text-transform: uppercase;
    line-height: 2;

    @media (max-width: ${props => props.theme.responsive.largeMax}) {
      font-size: ${props => props.theme.fontSizes[2]}px;
      line-height: 2;
    }
    
  }

  @media (min-width: ${props => props.theme.responsive.large}) {
    &.mobilemenu{
      flex: 0 !important;
    }
    &.mobilemenu ul{
        display: block !important;
    }
  }

  li {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 0;
    padding: 0 1.5rem;
    border-bottom: 2px solid ${props => props.theme.colors.text};
    &.dark{
      background-color: #000;    
      border-bottom-color: #fff !important;
      > a{
        color: #fff !important;
      }
    }
    &:nth-last-of-type(1):not(.dark){
      border-bottom: none;
    }
  }

  a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    color: #000;
  }
`

const activeLinkStyle = {
  color: 'black',
}

const Menu = props => {
  const { menuLinks } = useSiteMetadata()
  return (
    <Nav className={props.mobilemenu ? "whitemenu mobilemenu" : "whitemenu"} >
      <ul>
        {menuLinks.map(link => (
          <li key={link.slug}>
            <Link to={link.slug} activeStyle={activeLinkStyle}>
              {link.name}
            </Link>
          </li>
        ))}
        {props.bottommenu && (
        <>
          <li className="dark">
            <Link to="/termine/">Termine</Link>
          </li>
          <li className="dark">
            <Link to="/fernwartung/">Fernwartung</Link>
          </li>
        </>
        )}
      </ul>
    </Nav>
  )
}

export default Menu
