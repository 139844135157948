import { css } from '@emotion/core'
export const globalStyles = css`
  @font-face {
    font-family: 'Telegraf';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/Telegraf-Regular.eot'); /* IE9 Compat Modes */
    src: local('Telegraf Regular'), local('Telegraf-Regular'),
      url('/fonts/Telegraf-Regular.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */ url('/fonts/Telegraf-Regular.woff2') format('woff2'),
      /* Super Modern Browsers */ url('/fonts/Telegraf-Regular.woff')
        format('woff'),
      /* Modern Browsers */ url('/fonts/Telegraf-Regular.ttf')
        format('truetype'),
      /* Safari, Android, iOS */ url('/fonts/Telegraf-Regular.svg#OpenSans')
        format('svg'); /* Legacy iOS */
    font-display: swap;
  }

  @font-face {
    font-family: 'Telegraf';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/Telegraf-Bold.eot'); /* IE9 Compat Modes */
    src: local('Telegraf Bold'), local('Telegraf-Bold'),
      url('/fonts/Telegraf-Bold.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */ url('/fonts/Telegraf-Bold.woff2') format('woff2'),
      /* Super Modern Browsers */ url('/fonts/Telegraf-Bold.woff')
        format('woff'),
      /* Modern Browsers */ url('/fonts/Telegraf-Bold.ttf') format('truetype'),
      /* Safari, Android, iOS */ url('/fonts/Telegraf-Bold.svg#OpenSans')
        format('svg'); /* Legacy iOS */
    font-display: swap;
  }

  @font-face {
    font-family: 'PPE_light';
    font-style: light;
    font-weight: 300;
    src: url('/fonts/PPEditorialNew-Light.eot'); /* IE9 Compat Modes */
    src: local('PPE_light'), local('PPE_light'),
      url('/fonts/PPEditorialNew-Light.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */ url('/fonts/PPEditorialNew-Light.woff2') format('woff2'),
      /* Super Modern Browsers */ url('/fonts/PPEditorialNew-Light.woff')
        format('woff'),
      /* Modern Browsers */ url('/fonts/PPEditorialNew-Light.ttf') format('truetype'),
      /* Safari, Android, iOS */ url('/fonts/PPEditorialNew-Light.svg#OpenSans')
        format('svg'); /* Legacy iOS */
    font-display: swap;
  }

  /* http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-weight: 400;
    font-size: 100%;
    font: inherit;
  }

  /* Fix scrollbar underlaying */

  html {
    overflow-x: hidden;
    overflow-y: scroll;
    max-width: 100%;
  }

  /* Added to Fix Footer to bottom of viewport */
  html,
  body {
    background: white;
    height: 100%;
    width: 100%;
    font-family: 'Telegraf', Helvetica, sans-serif;
  }
  .siteRoot {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .siteContent {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
  footer {
    width: 100%;
  }

  /* End Fix to Place Footer on Bottom of Viewport */

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  @media screen and (min-width: 35em) {
    html {
      margin-right: calc(-100vw + 100%);
      overflow-x: hidden;
    }
  }

  ol,
  ul,
  li {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  body {
    line-height: 1.5;
    font-size: 15px;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
    font-weight: 400;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  button,
  input,
  textarea,
  select {
    font-family: 'Telegraf', Arial, Helvetica, sans-serif;
    background: none;
    border: none;
    appearance: none;
    border-radius: 0;
    resize: none;
    &:invalid {
      box-shadow: none;
    }
    &:focus {
      outline: 5px auto #ed6941;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }

  body:not(.user-is-tabbing) button:focus,
  body:not(.user-is-tabbing) input:focus,
  body:not(.user-is-tabbing) select:focus,
  body:not(.user-is-tabbing) textarea:focus,
  body:not(.user-is-tabbing) a:focus {
    outline: none;
  }

  ::placeholder,
  :-ms-input-placeholder,
  ::-ms-input-placeholder { 
    color: ${props => props.theme.colors.text} !important;
    opacity: 1 !important; 
  }
`
