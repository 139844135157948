import React from 'react'
import styled from '@emotion/styled'
import MobileMenu from './MenuMobile'
import Logo from './Logo'

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  height: 100px;
  z-index: 999;
  transition: top 0.4s;

  @media (min-width: ${props => props.theme.responsive.medium}) {
    top: -110px;
    &.hidden {
      top: 0 !important;
    }
  }

  &.hidden {
    top: -110px;
  }
`

const Header = styled.div`
  max-width: 1404px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  border-bottom: 2px solid #000;
  border-top: none !important;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  padding: 10px 15px 10px 30px;

  @media (min-width: ${props => props.theme.responsive.medium}) {
    padding: 10px 60px;
    border: 2px solid #000;
  }

  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    .ark-mobile-btn svg {
      max-width: 26px;
    }
  }

  .ark-mobile-btn {
    cursor: pointer;
  }
`

const MenuBar = styled.div``

class HeaderMobile extends React.Component {
  constructor(props) {
    super(props)

    if (typeof window !== 'undefined') {
      let prevScrollpos = window.pageYOffset
      window.onscroll = function() {
        const maxScroll = document.body.clientHeight - window.innerHeight
        let currentScrollPos = window.pageYOffset
        if (
          (maxScroll > 0 &&
            prevScrollpos > currentScrollPos &&
            prevScrollpos <= maxScroll) ||
          (maxScroll <= 0 && prevScrollpos > currentScrollPos) ||
          (prevScrollpos <= 0 && currentScrollPos <= 0)
        ) {
          document.getElementById('navbarmobile').classList.remove('hidden')
        } else {
          document.getElementById('navbarmobile').classList.add('hidden')
        }
        prevScrollpos = currentScrollPos
      }
    }
  }

  render() {
    return (
      <HeaderContainer id="navbarmobile">
        <Header>
          <Logo passColor={this.props.passColor} radiusLang={'999px'} radiusKurz={'0px'} />

          <MenuBar>
            <button
              className="ark-mobile-btn"
              onClick={() => this.toggleMenu()}
            >
              <svg viewBox="0 0 35 22" width="35" height="22">
                <rect width="100" height="2"></rect>
                <rect y="10" width="100" height="2"></rect>
                <rect y="20" width="100" height="2"></rect>
              </svg>
            </button>

            <MobileMenu ref={el => (this.childMenu = el)} />
          </MenuBar>
        </Header>
      </HeaderContainer>
    )
  }

  toggleMenu() {
    this.childMenu.open()
  }
}

export default HeaderMobile
