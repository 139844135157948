import React from 'react'
import styled from '@emotion/styled'
import Menu from './Menu'
import MenuBottom from './MenuBottom'
import MenuBottomNewsletter from './MenuBottomNewsletter'

const Wrapper = styled.div`
  position: relative;
  height: auto;

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-left: 2px solid #000;
  border-right: 2px solid #000;
  max-width: ${props => props.theme.sizes.maxWidth};
  width: 100%;
  margin: 0 auto;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    border-left: none !important;
    border-right: none !important;
  }
`

const HeroLeft = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  border-right: 2px solid #000;
  max-width: calc(100% - 280px);
  width: 100%;

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    max-width: none !important;
    border-right: none;
    flex-flow: row wrap;
  }
`

const HeroRight = styled.div`
  max-width: 280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    display: none !important;
  }
`

const HeroContainer = props => {
  return (
    <Wrapper>
      <HeroLeft>{props.children}</HeroLeft>
      <HeroRight>
        <Menu bottommenu={!props.nobottom} />
        {!props.nobottom && <MenuBottom />}
        {props.newsletter && <MenuBottomNewsletter/>}
      </HeroRight>
    </Wrapper>
  )
}

export default HeroContainer
