import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'

const TelefonContainer = styled.a``

const Telefon = () => {
  const telefonQuery = useStaticQuery(graphql`
    {
      contentfulCompany(unternehmensnamekurz: { eq: "EXTENDIT" }) {
        telefonnummer
      }
    }
  `)
  const telefonnummer = `tel:${telefonQuery.contentfulCompany.telefonnummer}`

  return (
    <TelefonContainer href={telefonnummer} onClick={() => typeof window !== "undefined" && window.gtag("event", "clickTelefon", {send_to: "AW-983019446/8KoeCJfTiskCELbf3tQD"})}>
      Tel {telefonQuery.contentfulCompany.telefonnummer}
    </TelefonContainer>
  )
}

export default Telefon