import React from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'

const EmailContainer = styled.a``

const Email = () => {
  const emailQuery = useStaticQuery(graphql`
    {
      contentfulCompany(unternehmensnamekurz: { eq: "EXTENDIT" }) {
        email
      }
    }
  `)
  const email = `mailto:${emailQuery.contentfulCompany.email}`

  return (
    <EmailContainer href={email} onClick={() => typeof window !== "undefined" && window.gtag("event", "clickEmail", {send_to: "AW-983019446/eTYmCInXiskCELbf3tQD"})}>
      Jetzt anfragen
    </EmailContainer>
  )
}

export default Email