import React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import LogoSVG from '../images/vectors/extendit-logo-full.inline.svg'
import { Link } from 'gatsby'

const LogoContainer = styled.div`
  max-width: 100%;
  width: 220px;
  height: auto;
  position: relative;
  opacity: 1 !important;
  z-index: 1;
  &:before,
  &:after {
    content: '';
    top: calc(50% - 39px);
    height: 70px;
    position: absolute;
    display: block;
    border: 2px solid ${props => props.assetColor || '#000'};
    z-index: 0;
  }
  &:before {
    width: 82%;
    left: -23px;
    border-radius: ${props => props.radius1 || 'none'};
  }
  &:after {
    width: 32%;
    right: -23px;
    border-radius: ${props => props.radius2 || 'none'};
  }
  &:hover:after,
  &:hover:before {
    background-color: ${props => props.assetColor};
  }

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    width: 180px;

    &:before,
    &:after {
      top: calc(50% - 32px);
      height: 56px;
    }
    &:before {
      width: 82%;
      left: -20px;
    }
    &:after {
      width: 32%;
      right: -20px;
    }
  }

  @media (max-width: ${props => props.theme.responsive.smallMax}) {
    width: 140px;

    &:before,
    &:after {
      top: calc(50% - 27px);
      height: 43px;
    }
    &:before {
      width: 82%;
      left: -16px;
    }
    &:after {
      width: 32%;
      right: -16px;
    }
  }
`

const LogoImage = styled(LogoSVG)`
  max-width: 100%;
  width: 100%;
  height: auto;
  max-height: 50px;
  position: relative;
  z-index: 1;
`

const Logo = props => {

  return (
    <Link
      to="/"
      css={css`
        opacity: 1 !important;
      `}
    >
      <LogoContainer assetColor={props.passColor} radius1={props.radiusLang} radius2={props.radiusKurz} >
        <LogoImage />
      </LogoContainer>
    </Link>
  )
}

export default Logo
