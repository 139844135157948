import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { Global } from '@emotion/core'
import Header from '../components/Header'
import HeaderMobile from '../components/HeaderMobile'
import Footer from '../components/Footer'
import { globalStyles } from '../styles/globalStyles.js'
import { useLocation } from '@reach/router'
import CookieConsent from 'react-cookie-consent'
import { CookiesProvider } from 'react-cookie'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import LinkedInTag from 'react-linkedin-insight'

const Root = styled.div`
  font-family: ${props => props.theme.fonts.body};
  * {
    box-sizing: border-box;
  }

  a {
    color: #000;
    text-decoration: none;
    &:hover {
      opacity: 0.7;
    }
  }

  .cookienoticetext{
    padding-right: 5px;
  }

  .cookienoticelink{
    color:#fff !important;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    padding-top: 100px;
  }

  .arkCookieContainer #rcc-confirm-button:hover,
  .arkCookieContainer #rcc-decline-button:hover {
    opacity: 0.8;
  }

  @media (max-width: 1404px) {
    .arkCookieContainer {
      max-width: 100% !important;
      left: 0 !important;
    }
  }
`

const Skip = styled.a`
  font-family: ${props => props.theme.fonts.body};
  padding: 0 1rem;
  line-height: 60px;
  background: #2867cf;
  color: white;
  z-index: 101;
  position: fixed;
  top: -100%;
  &:hover {
    text-decoration: underline;
  }
  &:focus,
  &:active,
  &:hover {
    top: 0;
  }
`

const Layout = props => {
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing')
    }
  }
  useEffect(() => window.addEventListener('keydown', handleFirstTab), [])

  const locationString = useLocation().pathname
  const matches = {
    'news': props => props.theme.colors.purple,
    'it-dienstleister': props => props.theme.colors.red,
    'mac-support': props => props.theme.colors.green,
    'it-infrastruktur': props => props.theme.colors.purple,
    'datensicherheit': props => props.theme.colors.grey,
    'digital-asset-management': props => props.theme.colors.red,
    'extendit-remote-service': props => props.theme.colors.green,
    'cloud-consulting-begleitung': props => props.theme.colors.purple,
    'client-management-systeme': props => props.theme.colors.grey,
    'canto-cumulus': props => props.theme.colors.green,
    'microsoft-365': props => props.theme.colors.purple,
    'kerio-connect': props => props.theme.colors.grey,
    'acronis-files-connect': props => props.theme.colors.red,
    'filewave': props => props.theme.colors.green,
    'fortigate': props => props.theme.colors.purple,
    'endpoint-protector': props => props.theme.colors.grey,
    'microsoft-exchange': props => props.theme.colors.red,
  }
  let assetColor = props => props.theme.colors.red
  for (const check in matches) {
    if (locationString.includes(check)) {
      assetColor = matches[check]
    }
  }

  let linkedInDisabled = false
  if (typeof document !== `undefined`) {
    linkedInDisabled = !document.cookie.match(/^(.*;)?\s*gatsby-gdpr-facebook-pixel\s*=\s*[^;]+(.*)?$/)
    LinkedInTag.init(3808857, 'dc', linkedInDisabled)
  }

  return (
    <CookiesProvider>
      <Root className="siteRoot">
        <div className="siteContent">
          <Skip href="#main" id="skip-navigation">
            Skip to content
          </Skip>
          <HeaderMobile passColor={assetColor} />
          <Header passColor={assetColor} passLocation={locationString} />
          <div id="main">{props.children}</div>
        </div>
        <Footer passColor={assetColor} />
        <CookieConsent
          location="bottom"
          buttonText="Ja, gerne"
          declineButtonText="Nein"
          setDeclineCookie={false}
          cookieName="gatsby-gdpr-facebook-pixel"
          flipButtons
          enableDeclineButton
          containerClasses="arkCookieContainer"
          expires={350}
          style={{
            background: '#000',
            border: '2px solid #000',
            color: '#fff',
            alignItems: 'center',
            justifyContent: 'center',
            borderBottom: 'none',
            margin: '0 auto',
            fontSize: '15px',
            maxWidth: '1404px',
            width: '100%',
            left: 'calc(50% - 702px)',
          }}
          buttonStyle={{
            background: '#00fe27',
            border: '2px solid #fff',
            color: '#000',
            fontSize: '15px',
          }}
          declineButtonStyle={{
            background: '#000',
            borderLeft: '2px solid #fff',
            borderRight: '2px solid #fff',
            color: '#fff',
            fontSize: '15px',
          }}
          onAccept={(acceptedByScrolling) => {
              LinkedInTag.init(3808857, 'dc', false)
          }}
        >
          <span className="cookienoticetext">Wir verwenden technische Cookies um unsere Webseite zu verbessern und
          freuen uns über Ihre Unterstützung.</span> <Link to="/datenschutz/" target="_blank" className="cookienoticelink">🠒 Mehr erfahren</Link>
        </CookieConsent>
        <Global styles={globalStyles} />
      </Root>
    </CookiesProvider>
  )
}

export default Layout
