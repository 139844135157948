import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '../hooks/use-site-metadata'

const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: ${props => props.theme.fontSizes[6]}px;
    line-height: 1.4;

    @media (max-width: ${props => props.theme.responsive.largeMax}) {
      font-size: ${props => props.theme.fontSizes[4]}px;
      line-height: 1.6;
    }
  }

  li {
    display: inline-block;
    margin-left: 0;
    padding: 0 1.5rem;
    &:first-of-type {
      position: relative;
      margin: 0;
      flex-basis: 100%;
    }
  }

  a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    color: #000;
  }
`

const activeLinkStyle = {
  color: 'black',
}

const Menu = props => {
  const { menuLinks } = useSiteMetadata()
  return (
    <Nav className="whitemenu" >
      <ul>
        {menuLinks.map(link => (
          <li key={link.slug}>
            <Link to={link.slug} activeStyle={activeLinkStyle}>
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
    </Nav>
  )
}

export default Menu
