import React from 'react'
import styled from '@emotion/styled'
import Logo from './Logo'
import SocialIcons from './SocialIcons.js'
import AppleLogo from './ApplePartner.js'
import Telefon from './Telefon.js'
import Email from './Email.js'

const HeaderLogo = styled.div`
  padding: 10px 0px 10px 60px;
  border-right: 2px solid #000;
  height: 100%;
  justify-content: flex-start;
  flex-grow: 1;

  @media (max-width: ${props => props.theme.responsive.desktopMax}) {
    padding: 10px 0px 10px 30px;
  }
`

const HeaderKontakt = styled.div`
  flex: 0 0 472px;
  max-width: 472px;
  border-right: 2px solid #000;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    flex: 0 0 440px;
    max-width: 440px;
    padding: 0 15px;
  }

  & > a{
    background-color: #000 !important;
    border-radius: 999px;
    padding: 17px 15px;
    display: inline-block;
    margin: 5px;
    color: #fff;
    max-width: 200px;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.7px;
    line-height: 1.4;
  }
`

const HeaderApple = styled.div`
  flex: 0 0 240px;
  max-width: 240px;
  border-right: 2px solid #000;
  justify-content: center;
  align-items: center;
  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    display: none !important;
  }
`

const HeaderSocial = styled.div`
  padding: 10px;
  display: flex;
  padding: 10px;
  flex: 0 0 280px;
  max-width: 280px;
  justify-content: center;
`

const HeaderContainer = styled.div`
  background: ${props => props.theme.colors.background};
  width: 100%;
  padding: 0;
  height: 180px;
  max-width: ${props => props.theme.sizes.maxWidth};
  border-left: 2px solid #000;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-flow: row nowrap;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    height: 120px !important; 
    border-left: none !important;
    border-right: none !important;
  }

  @media (max-width: ${props => props.theme.responsive.mediumMax}) {
    display: none !important;
  }

  ${HeaderLogo},
  ${HeaderSocial},
  ${HeaderApple},
  ${HeaderKontakt}{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }

`

const Header = props => {

  return (
    <HeaderContainer>
      <HeaderLogo>
        <Logo passColor={props.passColor} radiusLang={'999px'} radiusKurz={'0px'} />
      </HeaderLogo>
      <HeaderKontakt>
        <Email />
        <Telefon />
      </HeaderKontakt>
      <HeaderApple>
        <AppleLogo /> 
      </HeaderApple>
      <HeaderSocial>
        <SocialIcons />
      </HeaderSocial>
    </HeaderContainer>
  )
}

export default Header
