import React, { useImperativeHandle, useRef } from 'react'
import styled from '@emotion/styled'
import MenuNav from '../components/Menu'
import MenuBottom from '../components/MenuBottom'

const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  min-height: 100%;
  width: 100%;
  z-index: 1001;
  visibility: hidden;
  opacity: 0;
  transition: all 0.35s;
  background: #fff;
  pointer-events: none;
  &.open {
    visibility: visible;
    opacity: 1;
  }

  .closebutton {
    position: absolute;
    top: 30px;
    right: 15px;
    left: auto;
    @media (min-width: ${props => props.theme.responsive.medium}) {
      top: 30px;
      right: 0px;
    }
  }

  .closebutton svg {
    display: inline-block;
    height: 30px;
    width: 30px;
    cursor: pointer;
    stroke: #000;
    pointer-events: none;
  }

// fix damit die svgs nicht klickbar sind während menü unsichtbar ist
  svg{
    pointer-events: none;
  }
  &.open svg{
    pointer-events: auto;
  }
`

const Wrapper = styled.div`
  position: relative;
  z-index: 1002;
  height: 100%;
  min-height: 100%;
  width: 100%;
  max-width: ${props => props.theme.responsive.mediumMax};
  margin: 0 auto;
  background: #ffffff;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: ${props => props.theme.responsive.medium}) {
    justify-content: flex-start;
  }
  align-items: center;

  .whitemenu li:first-of-type {
    border-top: 2px solid #000;
  }
  .whitemenu li {
    border-bottom: 2px solid #000;
    @media (min-width: ${props => props.theme.responsive.medium}) {
      border-left: 2px solid #000;
      border-right: 2px solid #000;
    }
  }

  a {
    pointer-events: all;
    cursor: pointer;
  }
`

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  render() {
    return (
      <MobileMenu
        role="button"
        tabIndex="0"
        className={`${this.state.open ? `open` : ''}`}
        onClick={() => this.close()}
        onKeyDown={() => this.close()}
      >
        <Wrapper>
          <MenuNav bottommenu mobilemenu />
          <MenuBottom />
          <div className="closebutton">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
              enableBackground="new 0 0 40 40"
            >
              <line
                x1="15"
                y1="15"
                x2="25"
                y2="25"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeMiterlimit="10"
              ></line>
              <line
                x1="25"
                y1="15"
                x2="15"
                y2="25"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeMiterlimit="10"
              ></line>
              <circle
                className="circle"
                cx="20"
                cy="20"
                r="19"
                opacity="0"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeMiterlimit="10"
                fill="none"
              ></circle>
              <path
                d="M20 1c10.45 0 19 8.55 19 19s-8.55 19-19 19-19-8.55-19-19 8.55-19 19-19z"
                className="progress"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeMiterlimit="10"
                fill="none"
              ></path>
            </svg>
          </div>
        </Wrapper>
      </MobileMenu>
    )
  }

  close() {
    this.setState({ open: false })
  }

  open() {
    this.setState({ open: true })
  }
}

export default React.forwardRef((props, ref) => {
  const menuRef = useRef()

  useImperativeHandle(ref, () => ({
    open() {
      menuRef.current.open()
    },
  }))

  return <Menu ref={menuRef} {...props} />
})
