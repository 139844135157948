import React from 'react'
import styled from '@emotion/styled'
import Newsletter from '../components/Newsletter'

const NewsletterContainer = styled.div`
  background-color: #000;
  display: flex;
  flex-flow: column nowrap;
  flex: auto;
  color: ${props => props.theme.colors.background};
  font-size: ${props => props.theme.fontSizes[3]}px;
  text-align: center;
  justify-content: center;
  border-top: 2px solid ${props => props.theme.colors.background};
  padding: 30px 0;
`

const MenuBottomNewsletter = props => {
  return (
    <NewsletterContainer>
      <p>Newsletter</p>
      <Newsletter mode="sidebar" />
    </NewsletterContainer>
  )
}

export default MenuBottomNewsletter
