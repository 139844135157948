/** @jsx jsx */
import AppleSVG from "../images/vectors/apple_black.inline.svg"
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/core'

const ApplePartnerContainer = styled.div`
    text-align: left;
    > *{
        vertical-align: middle;
    }
`

const AppleLogo = styled(AppleSVG)`
  max-width: 100%;
  width: 24px;
  height: auto;
  margin-right: 6px;
  margin-bottom: 10px;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    width: 26px;
  }
`

const ApplePartner = () => {
    return(
        <ApplePartnerContainer>
            <AppleLogo /> <div css={css`display:inline-block;`}>Autorisierter Händler</div>
        </ApplePartnerContainer>
    ) 
}

export default ApplePartner