/** @jsx jsx */
import styled from '@emotion/styled'
import { css, jsx } from '@emotion/core'

const Wrapper = styled.div`
  display: block;
  * {
    font-family: inherit;
  }

  label {
    display: none;
  }

  .cr_ipe_item {
    width: auto;
    display: inline-block;
  }

  .editable_content {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      flex-flow: column;
    }

    @media (max-width: ${props => props.theme.responsive.smallMax}) {
      width: 100%;
    }
  }

  .cr_ipe_item:first-of-type,
  #mce-EMAIL {
    width: 59%;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      width: 100%;
    }
  }

  .cr_ipe_item:last-child {
    width: 39%;
    text-align: right;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      width: 100%;
      text-align: left;
    }
  }

  #mce-EMAIL {
    width: 59%;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      width: 100%;
    }
  }

  #mc-embedded-subscribe {
    width: 39%;
    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      width: 100%;
    }
  }

  #mc_embed_signup_scroll {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  input,
  .cr_button,
  #mce-EMAIL,
  #mc-embedded-subscribe {
    max-width: 100%;
    color: ${props => props.theme.colors.text};
    border: 2px solid ${props => props.theme.colors.text};
    height: 70px;
    line-height: 70px;
    padding: 0 14px;
    font-size: ${props => props.theme.fontSizes[5]}px;

    @media (max-width: ${props => props.theme.responsive.largeMax}) {
      font-size: ${props => props.theme.fontSizes[3]}px;
      margin-top: 20px;
    }

    @media (max-width: ${props => props.theme.responsive.mediumMax}) {
      margin-top: 15px;
      padding: 0 15px;
      line-height: 50px;
      height: 50px;
      width: 100%;
      max-width: 250px;
      min-width: 200px;
      font-size: ${props => props.theme.fontSizes[2]}px;
    }
    @media (max-width: ${props => props.theme.responsive.smallMax}) {
      margin-top: 10px;
      padding: 0 10px;
      line-height: 40px;
      height: 40px;
      width: 100%;
      max-width: 250px;
      min-width: 200px;
      font-size: ${props => props.theme.fontSizes[2]}px;
    }
  }

  input,
  #mce-EMAIL {
    border-radius: 999px;
    padding: 0 20px;
  }

  .cr_button,
  #mc-embedded-subscribe {
    cursor: pointer;
    border-radius: 0 !important;
  }

  .cr_button:hover,
  #mc-embedded-subscribe:hover {
    background: ${props => props.theme.colors.text};
    color: ${props => props.theme.colors.background};
  }

  // Sidebar Newsletter
  &.sidebar {
    .cr_ipe_item {
      width: auto;
      display: inline-block;
    }

    .editable_content {
      width: 100%;
      display: flex;
      max-width: 100%;
      flex-flow: column nowrap;
      justify-content: space-between;
    }

    #mce-EMAIL {
      width: 100%;
    }

    input,
    .cr_button,
    #mce-EMAIL,
    #mc-embedded-subscribe {
      max-width: 90%;
      margin: 0 auto;
      color: ${props => props.theme.colors.text};
      border: 2px solid ${props => props.theme.colors.text};
      font-size: ${props => props.theme.fontSizes[2]}px !important;
      text-align: center;
      padding: 0 14px;
      line-height: 46px !important;
      height: 50px !important;
      border: 2px solid #fff;
    }

    .cr_button,
    #mc-embedded-subscribe {
      margin-top: 5px;
      color: ${props => props.theme.colors.background};
      border: none;
      height: auto;
      line-height: 1.5em;
      padding: 0px 10px;
      border: 2px solid #fff;
      width: 74%;
      background-color: transparent !important;
    }

    .cr_button:hover,
    #mc-embedded-subscribe:hover {
      opacity: 0.8;
    }

    input,
    #mce-EMAIL {
      margin-top: 10px;
      background: ${props => props.theme.colors.background};
    }

    .cr_ipe_item:first-of-type {
      width: 100%;
    }

    .cr_ipe_item:last-child {
      width: 100%;
      text-align: center;
    }

    a {
      color: ${props => props.theme.colors.background};
    }
  }
`

const Optin = styled.div`
  display: inline-block;
  width: 100%;
`

const Disclaimer = styled.div`
  font-size: 0.8rem;
  margin-top: 10px;

  a {
    text-decoration: underline;
  }
`

const Newsletter = props => {
  return (
    <Wrapper className={`${(props.mode && props.mode) || ''}`}>
      <Optin>
        <div className="ark-newsletter-form">
          <div id="mc_embed_signup">
            <form
              action="https://extendit.us2.list-manage.com/subscribe/post?u=1ec71d31edf40ac554e539f56&id=6c11d100ae"
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              className="validate"
              target="_blank"
            >
              <div id="mc_embed_signup_scroll">
                <input
                  type="email"
                  name="EMAIL"
                  className="email"
                  id="mce-EMAIL"
                  placeholder="E-Mail-Adresse"
                  required
                />
                <div
                  className="mc-field-group input-group"
                  css={css`
                    display: none !important;
                  `}
                >
                  <ul>
                    <li>
                      <input
                        type="checkbox"
                        value="1"
                        name="group[83558][1]"
                        id="mce-group[83558]-83558-0"
                        defaultChecked
                      />
                    </li>
                  </ul>
                </div>

                <div
                  style={{ position: 'absolute', left: '-5000px' }}
                  aria-hidden="true"
                >
                  <input
                    type="text"
                    name="b_1ec71d31edf40ac554e539f56_6c11d100ae"
                    tabIndex={-1}
                  />
                </div>
                <input
                  type="submit"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button"
                  value="Anmelden"
                />
              </div>
            </form>
          </div>
        </div>
      </Optin>
      <Disclaimer>
        Wir verarbeiten Ihre Daten gemäß{' '}
        <a href="/datenschutz" target="_blank" rel="noopener">
          Datenschutzbestimmungen
        </a>
        .
      </Disclaimer>
    </Wrapper>
  )
}

export default Newsletter
