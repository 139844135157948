import React from 'react'
import styled from '@emotion/styled'
import FBSVG from '../images/vectors/fb.inline.svg'
import InstaSVG from '../images/vectors/insta.inline.svg'
import TwitterSVG from '../images/vectors/twitter.inline.svg'
import { useStaticQuery, graphql } from 'gatsby'

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const SocialLink = styled.a`
  width: 64px;
  height: 64px;
  border: 2px solid #000;
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    width: 52px;
    height: 52px;
  }
`

const Facebook = styled(FBSVG)`
  max-width: 100%;
  width: 15px;
  height: auto;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    width: 12px;
  }
`

const Insta = styled(InstaSVG)`
  max-width: 100%;
  width: 30px;
  height: auto;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    width: 20px;
  }
`

const Twitter = styled(TwitterSVG)`
  max-width: 100%;
  width: 30px;
  height: auto;

  @media (max-width: ${props => props.theme.responsive.largeMax}) {
    width: 20px;
  }
`

const SocialIcons = () => {
  const socialQuery = useStaticQuery(graphql`
    {
      contentfulCompany(unternehmensnamekurz: { eq: "EXTENDIT" }) {
        facebook
        twitter
        instagram
      }
    }
  `)

  return (
    <SocialContainer>
      <SocialLink href={socialQuery.contentfulCompany.facebook} target="_blank">
        <Facebook />
      </SocialLink>
      <SocialLink
        href={socialQuery.contentfulCompany.instagram}
        target="_blank"
      >
        <Insta />
      </SocialLink>
      <SocialLink href={socialQuery.contentfulCompany.twitter} target="_blank">
        <Twitter />
      </SocialLink>
    </SocialContainer>
  )
}

export default SocialIcons
