import React from 'react'
import styled from '@emotion/styled'
import AppleSVG from '../images/vectors/apple_white.inline.svg'
import WindowsSVG from '../images/vectors/windows_white.inline.svg'

const MenuBottomDark = styled.div`
  background-color: #000;
  height: auto;
  width: 100%;
  color: #fff;
  flex-grow: 0;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    line-height: 1.29;
    font-size: ${props => props.theme.fontSizes[2]}px;
    line-height: 2;
    text-transform: uppercase;
    @media (max-width: ${props => props.theme.responsive.largeMax}) {
      font-size: ${props => props.theme.fontSizes[2]}px;
      line-height: 2;
    }
  }

  li {
    border-bottom: 2px solid #fff;
    display: flex;
    align-items: center;
    margin-left: 0;
    padding: 0 1.5rem;
  }

  a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    color: #fff;
  }
`

const FWMenu = styled.div`
  display: flex;
  flex-direction: row;
`

const StyledApple = styled(AppleSVG)`
  width: 24px;
  height: auto;
`

const StyledWindows = styled(WindowsSVG)`
  width: 24px;
  height: auto;
`

const FWLogo = styled.a`
  flex-grow: 1;
  height: 70px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: relative;
  opacity: 1 !important;
  &:hover * {
    opacity: 0.7;
  }
  &:nth-of-type(1)::after {
    content: '';
    position: absolute;
    top: 0;
    right: 1px;
    width: 2px;
    height: 100%;
    background: ${props => props.theme.colors.background};
    opacity: 1 !important;
  }
`

const MenuBottom = () => {
  return (
    <MenuBottomDark className="dark">
        <FWMenu>
          <FWLogo
            href="https://download.teamviewer.com/download/TeamViewerQS.dmg"
            rel="noreferrer"
          >
            <StyledApple />
          </FWLogo>
          <FWLogo
            href="https://download.teamviewer.com/download/TeamViewerQS.exe"
            rel="noreferrer"
          >
            <StyledWindows />
          </FWLogo>
        </FWMenu>
    </MenuBottomDark>
  )
}

export default MenuBottom
